@media (max-width: 1234px) {
    .sidebar-custom {
        display: none;
    }
}

.submenu-custom .no-scrollbar::-webkit-scrollbar {
    display: none;
}

.allOptions, .Terrain, .Appartement, 
.Villa, .Studio, 
.Bureau, .Magasin, 
.Appartement .Meublé{
    background-color: rgba(0, 211, 141, 0.31);
}