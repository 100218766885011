@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wdth,wght@91.3,300..800&family=Quicksand:wght@300..700&display=swap');
@layer base {
  body {
    @apply font-primary bg-gray-200;
    font-family: 'Open Sans';
    overflow-x: hidden;
  }
  .dropdown {
    @apply w-full lg:max-w-[296px] cursor-pointer;
  }
  .dropdown-btn {
    @apply flex h-[64px] items-center px-[18px] border rounded-lg;
  }
  .dropdown-icon-primary {
    @apply text-2xl mr-[18px] text-[#00C040];
  }
  .dropdown-icon-secondary {
    @apply text-2xl text-[#00C040] ml-auto;
  }
  .dropdown-menu {
    @apply px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;
  }
}

.card{
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
}

.card .top{
  text-align: center;
}

.card p{
  font-weight: bold;
  color: [#00008B];
}

.card button{
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background: [#00008B];
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
}

.card .drag-area .visible{
  font-size: 18px;
}
.card .select{
  color: [#00008B];
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.card .select:hover{
  opacity: 0.6;
}
.card .container{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}
.card .container .image{
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom: 8px;
}

.card .container .image img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.card .container .image span{
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible{
  display: none;
}

.delete{
  z-index: 999;
  color: [#00008B];
}